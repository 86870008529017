/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Links on the right */
.navbar a.right {
  float: right;
}

/* Change color on hover */
.navbar a:hover {
  /* background-color: #0da0de; */
  background-color: #3b3b3b;

  color: white;
}

/* Active link */
.navbar a.active {
  background-color: #4caf50;
  color: white;
}

/* Logo on the left */
.navbar .logo {
  font-size: 25px;
    margin-left: 20px;
    font-weight: 700;
    font-family: math;
}


/* Navbar container */
.navbar {
  overflow: hidden;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  box-shadow: 0px -3px 10px #0da0de;
  box-shadow: -4px -70px 136px 3px #0da0de;
  position: fixed;
  top: 0;
  width: -webkit-fill-available;
  z-index: 100;
}


/* Logo and links */
.navbar a {
  display: block;
  color: #1916ff;
  text-align: center;
  text-decoration: none;
  /* display: grid;
  grid-template-columns: repeat(12,1fr);
  position: relative; */
}

/* Hamburger Icon */
.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

/* Center Links */
.center {
  display: flex;
}

.center a {
  margin: 0 16px;
  font-size: 18px;
    font-weight: 500;
}

/* Right Logos */
.right {
  display: flex;
}

.right a {
  margin: 0 10px;
}

.menu-div-open {
  display: flex;
  justify-content: space-between;
  width: auto;
}


@media (max-width: 768px) {
  .section-head {
    font-size: 40px;
  }

  .center,
  .right {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .menu-div-open {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .center a,
  .right a {
    margin: 10px 0;
  }

  .hamburger {
    display: block;
  }

  .menu-open {
    display: flex;
    flex-direction: column;
  }

  .menu-icon-open {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  /* Hamburger Animation */
  .bar.change:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .bar.change:nth-child(2) {
    opacity: 0;
  }

  .bar.change:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 550px;
  }

  .text-content {
    padding: 0;
    margin-left: 0;
  }
}







/* slider css */

.slider {
  position: relative;
  overflow: hidden;
  height: 700px;
  width: 100%;
}

.slider-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 0.6s ease-in-out;
  transform: translateX(-100%);
}

.slide.active {
  transform: translateX(0);
}

.slide-content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.next-button {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  color: white;
  font-size: 90px;
  cursor: pointer;
}

.next-button>.right-icon {
  height: 200px;
}



.tile {
  position: fixed;
  bottom: 6%;
  /* height: 100px; */
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: fade-in 0.3s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.tile-content {
  display: flex;
  background-color: #fff;
  height: 100px;
  width: max-content;
  align-items: center;
  padding: 10px;
}

.tile-item {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  width: inherit;
}


/* .tile {
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

/* .tile-item {
  margin-bottom: 20px;
} */

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="date"],
input[type="time"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

input[type="date"]:focus,
input[type="time"]:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #fff;
}

select:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.search-button {
  background-color: #4CAF50;
  /* Green */
  color: #ffffff;
  /* White */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
}

.search-button:hover {
  background-color: #3e8e41;
  /* Darker green on hover */
  color: #ffffff;
  /* White */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  animation: pulse 0.5s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .tile {
    top: 50%;
    height: fit-content;
  }

  .tile-content {
    display: block;
    height: auto;

  }

  .tile-content>div {
    margin-top: 20px;
  }

}



.step-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}

.step-number {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.step-text {
  font-size: 16px;
  margin-bottom: 5px;
}

.step-description {
  font-size: 14px;
  margin-bottom: 20px;
}

/* Add animation to the section */
.step-info {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .step-info {
    flex-direction: column;
  }

  .step {
    margin: 20px 0;
  }
}

/* /////////////////////////////// */

.step-info {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* flex-direction: column; */
  width: 100%;
  /* padding: 20px; */
  padding: 20px;
  perspective: 800px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  transform-style: preserve-3d;
  transform: rotateX(10deg) rotateY(10deg);
  transition: transform 0.5s ease-in-out;
}

.step:nth-child(1) {
  animation-delay: 0s;
}

.step:nth-child(2) {
  animation-delay: 0.2s;
}

.step:nth-child(3) {
  animation-delay: 0.4s;
}

.step:hover {
  transform: rotateX(10deg) rotateY(-10deg);
}

.step-number {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  /* text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); */
}

.step-text {
  font-size: 16px;
  margin-bottom: 5px;
  /* text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); */
}

.step-description {
  font-size: 14px;
  margin-bottom: 20px;
  /* text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); */
}

/* Add animation to the section */
.step-info {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .step-info {
    flex-direction: column;
  }

  .step {
    margin: 20px 0;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}









.open-button {
  position: fixed;
  bottom: 40px;
  left: 20px;
  background-color: #4CAF50;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
  animation: bounce 1s ease-in-out infinite;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.open-button:hover {
  background-color: #3e8e41;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000000;
  padding: 5px 5px;
  border: solid 1px black;
  border-radius: 50%;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}



.close-button svg {
  width: 14px;
  height: 14px;
  /* fill: #000000; */

}

.close-button:hover {
  background-color: #e61b0c;
  color: #ffffff;
  border-color: #f2f2f2;
}






.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.lottie-animation {
  width: 500px;
  height: 500px;
}

.text-animation {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 20px;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid orange;
  animation: typing 3.5s steps(30, end), blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: orange;
  }
}





.section--animate {
  opacity: 0;
  transform: translateY(50px);
}

.lottie-animation-step-gif {
  width: 100px;
  height: 100px;
}

.flex {
  display: flex;
}



.section-2 {
  height: 700px;
  margin-bottom: 274px;
  margin-top: 274px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.section-2>.left {
  animation: slideInLeft 2s ease-out forwards;
  /* animation-timeline: view(600px); */
  width: 30%;
}

.section-2>.right {
  animation: slideInRight 2s ease-out forwards;
  /* animation-timeline: view(600px); */

  width: 30%;
  height: 400px;
  object-fit: cover;
  border-radius: 30px;
}

.check-circle {
  margin-right: 16px;
  margin-bottom: 10px;
  background-color: #000;
  color: white;
  font-size: 20px;
  padding: 9px;
}

.section-2>.left>.read-more-btn {
  padding: 15px 20px;
  border-radius: 20px;
  border: none;
  font-size: 15px;
  background-color: #ffbc00;
  cursor: pointer;
}

.section-2>.left>.read-more-btn:hover {
  background-color: #d09903;
}




@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.section-2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
}

.section-2-h5{
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 10px;
}

.section-2-h1{
  font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
      color: #333;

}
.section-2-p{
  margin-bottom: 10px;
    text-align: justify;
}
.section-2-h3{
  margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
    color: #d62b2bbf;
}
.section-2-p2{
  font-size: 13px;
    text-align: justify;
}
.check-circle {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* width: 25px; */
  height: 25px;
  background-color: #fff;
  color: #fff;
  border-radius: 50%;
  margin-right: 10px;
}

.check-circle svg{
  color: #000;
}
.read-more-btn {
  display: flex;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.read-more-btn svg {
  margin-left: 5px;
}

/* section 2 -home page */
@media (max-width: 768px) {
  .section-2 {
    flex-direction: column;
    width: 80%;
    height: max-content;
    margin: 0;
  }

  .section-2 >.right {
    display: block;
    width: 100%;
    margin-top: 16px; 
    /* padding-bottom: 20px; */
  }
  .section-2 > .left{
    margin-top: 20px;
    width: 100%;
    /* padding: 10px; */
    margin: 0;
  }
}



/* footer css */

footer{
  padding: 20px 90px;
  background-color: #000;
  color: #ccc;
  height: 100%;
}
.contact-card{
  display: flex;
  justify-content: space-evenly;
  border: black solid 1px;
  padding: 20px 90px;
  border-radius: 20px;
  margin: 50px 0px;

}

.con-card-div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.con-card-div-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 20px;
}

.con-card-div > div > h5{
  margin: 0px;
}

.con-card-div > div > p{
  margin: 0px;
}


.footer-card{
  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */
  margin-bottom: 50px;
}

.footer-card > div{
  width: 20%;
}

.logo-div > .logo{
  font-size: 30px;
  font-weight: 700;
}

.logo-div > p{
  font-size: 14px;
  font-weight: 400;
  text-align: justify;
}

.logo-div > .logo{
  color: white;
}

.social-icon-div{
  display: flex;
  /* justify-content: space-between; */
}

.social-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 15px;
}

.quick-links-div > h4{
  font-size: 20px;
  font-weight: 700pxs;
  padding: 0px;
  /* margin: 0px; */
  margin-bottom: 12px;
}

.quick-links-div > ul > li{
margin-bottom: 10px;
}

.subscribe-div > h4{
  font-size: 20px;
  font-weight: 700pxs;
  padding: 0px;
  /* margin: 0px; */
  margin-bottom: 12px;
}

.subscribe-div > p{
  font-size: 14px;
  font-weight: 400;
  text-align: justify;
  margin-bottom: 12px;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
  position: relative;
}

.input-box {
  width: 100%;
  padding: 10px 15px;
  border: 2px solid #121212;
  border-radius: 25px;
  outline: none;
  font-size: 16px;
  padding-right: 50px; /* Make space for the button */
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

.input-button {
  position: absolute;
  right: 5px;
  background-color: #1c1c1d;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-button .button-icon {
  font-size: 16px;
}

.copyright{
  text-align: right;
}


@media (max-width: 768px){
  footer{
    height: max-content;
  }
  .contact-card{
    flex-direction: column;
    /* justify-content: space-between; */
    padding: 0;
    align-items: flex-start;
  }
  .con-card-div {
    margin-bottom: 25px;
  }
  .footer-card{
    flex-direction: column;
  }
  .footer-card > div{
    width: 100%;
  }
}










/* faqs css */

.faq-section {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.faq-section h2 {
  text-align: center;
  margin-bottom: 20px;
  /* font-size: 24px; */
  /* color: #333; */
  margin-bottom: 20px;
    font-size: 25px;
    font-weight: 600;
}

.faqs {
  border-top: 1px solid #ddd;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
}

.faq-question {
  cursor: pointer;
  font-size: 18px;
  color: #007BFF;
  margin: 0;
  transition: color 0.3s ease;
}

.faq-question:hover {
  color: #0056b3;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  font-size: 16px;
  color: #333;
  margin-top: 10px;
  line-height: 1.5;
}

.faq-answer.show {
  max-height: 200px; /* Adjust as needed */
}






.featured-classic-cars {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
}
.featured-classic-cars >h2{
  margin-bottom: 20px;
    font-size: 25px;
    font-weight: 600;
}
.car-slide {
  position: relative;
}

.car-image {
  width: 100%;
  height: auto;
}

.car-info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.car-info h3 {
  margin: 0;
}

.car-info p {
  margin: 5px 0 0;
}

@media (max-width: 480px) {
  .car-info {
    bottom: 5px;
    left: 5px;
    padding: 6px;
  }

  .car-info h3 {
    font-size: 1em;
  }

  .car-info p {
    font-size: 0.8em;
  }
}


.why-choose-us {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.why-choose-us > h2 {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 600;
}

.usp-section, .testimonials-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.usp {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
}

.usp:nth-child(1){
  background-color: #7f82ff63;
}
.usp:nth-child(2){
background-color: #fcff7f63;
}
.usp:nth-child(3){
  background-color: #8bff7f63;
}

.usp h3 {
  margin-top: 0;
  font-size: 18px;
    font-weight: 700;
}

.usp p {
  margin: 10px 0 0;
}

.testimonial {
  background: #f0f0f0;
  padding: 15px;
  border-radius: 8px;
  width: 100%;
}

.testimonial p {
  font-style: italic;
  margin: 0;
}

.testimonial h4 {
  margin-top: 10px;
  font-weight: normal;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .usp-section, .testimonials-section {
    flex-direction: row;
    justify-content: space-between;
  }

  .usp, .testimonial {
    width: 30%;
  }
}

@media (max-width: 768px) {
  .usp, .testimonial {
    width: 100%;
  }
}


/* src/styles.css */
.bg-cover {
  background-size: cover;
}
.bg-center {
  background-position: center;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
}




/* src/index.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10; /* Ensure the navbar is above other elements */
}

.menu-div-open {
  display: flex;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
  text-decoration: none;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
  transition: 0.4s;
}

.center a {
  margin: 0 1rem;
  text-decoration: none;
  color: #333;
}

.right a {
  margin: 0 0.5rem;
  text-decoration: none;
  color: #333;
}

.menu-open .center, .menu-open .right {
  display: block;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .center, .right {
    display: none;
    width: 100%;
    text-align: center;
  }

  .center.menu-open, .right.menu-open {
    display: flex;
    flex-direction: column;
  }

  .center a, .right a {
    margin: 0.5rem 0;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rounded-md {
  border-radius: 0.375rem;
}

.bg-white {
  background-color: #fff;
}

.bg-gray-200 {
  background-color: #edf2f7;
}

.text-gray-700 {
  color: #4a5568;
}

.hover\:bg-gray-200:hover {
  background-color: #edf2f7;
}

.z-10 {
  z-index: 10; /* Ensure the dropdown is above other elements */
}


.search-tile{
  /* position: absolute; */
  /* display: ruby; */

    /* transform: translate(-50%, -50%);
    left: 50%;
    top: 20%; */
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 7%;
  border-radius: 5px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}


@media (max-width: 768px){
  /* .search-tile{
    top: 60%;
    margin-top: 20%;
    transform: translate(-50%, -20%);
  } */
}




.distance-info {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  text-align: center;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .distance-info {
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }
}





/* map selection page css */

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.map-selection-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.controls {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 20px);
  max-width: 500px;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

.coordinates {
  margin: 0;
  font-size: 14px;
  color: #333;
  background: #f8f8f8;
  padding: 5px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.button {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}

.button > p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.distance {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
  background: #f8f8f8;
  padding: 5px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
}



/* summary details page css */

/* src/components/SummaryPage.css */

.summary-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1, h2, h3 {
  color: #333;
}

.search-criteria, .selected-cars, .location-info, .contact-person-details {
  margin-bottom: 20px;
}

.selected-cars {
  margin-top: 20px;
}

.car-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.car-item img {
  width: 100px;
  height: auto;
  margin-right: 10px;
}

.car-details {
  flex: 1;
}

.location-info h3, .distance-info h3, .contact-person-details h3 {
  margin-bottom: 10px;
}

.input-group {
  margin-bottom: 10px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.checkbox-group {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.checkbox-group label {
  margin-left: 5px;
}

.buttons {
  margin-top: 20px;
}

.buttons button {
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.buttons button:hover {
  background-color: #0056b3;
}







/* summary page css */

.summary-page {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.summary-page h1 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.summary-page h2, .summary-page h3 {
  color: #444;
  margin-bottom: 10px;
}

.search-criteria, .selected-cars, .location-info, .contact-person-details, .chauffeur-option, .price-breakdown, .total-price, .confirm-booking {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.car-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.car-item img {
  width: 100px;
  height: auto;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.car-details h3 {
  margin: 0;
}

.car-details p {
  margin: 5px 0;
}

.input-group, .checkbox-group, .chauffeur-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-group label, .checkbox-group label, .chauffeur-option label {
  margin-right: 10px;
  width: 120px;
}

.input-group input, .checkbox-group input, .chauffeur-option input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
}

.price-breakdown ul {
  list-style-type: none;
  padding: 0;
}

.price-breakdown ul li {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
}

.total-price p, .price-breakdown p {
  font-size: 1.2em;
  font-weight: bold;
}

.confirm-booking {
  text-align: center;
}

.confirm-booking button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.confirm-booking button:hover {
  background-color: #218838;
}



/* bookings page css */

.bookings-page {
  padding: 20px;
}
.bookings-page > h1{
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.back-button {
  margin-bottom: 20px;
}

.booking-items {
  list-style: none;
  padding: 0;
}

.booking-item {
  border: 1px solid #ccc;
  margin-bottom: 20px;
  padding: 10px;
}

.booking-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.booking-details {
  display: flex;
}

.booking-image {
  width: 200px;
  margin-right: 20px;
}

.booking-image img {
  width: 100%;
  height: auto;
}

.booking-info {
  flex: 1;
}

.booking-info p {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .booking-details {
    flex-direction: column;
  }

  .booking-image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}






/* About page css */
.about-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.section {
  margin-bottom: 40px;
}

.section h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.section p {
  font-size: 1.2em;
  line-height: 1.6;
}

.card-container {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.card {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.3s ease;
}

.card:hover {
  cursor: pointer;
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card img {
  width: 100%;
  border-radius: 8px;
}

.card h3 {
  margin-top: 10px;
  font-size: 1.5em;
}

.card p {
  font-size: 1.1em;
  color: #666;
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 80%;
    margin-bottom: 20px;
  }
}

/* Responsive styles for mobile devices */
@media (max-width: 480px) {
  .section h2 {
    font-size: 1.5em;
  }

  .section p {
    font-size: 1em;
  }

  .card {
    width: 100%;
    margin-bottom: 20px;
  }

  .card h3 {
    font-size: 1.2em;
  }

  .card p {
    font-size: 0.9em;
    padding: 0 10px 10px;
  }
}





/* classic page site  */

.classic-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.section {
  margin-bottom: 40px;
}

.text-image {
  display: flex;
  align-items: center;
  gap: 20px;
}

.reverse {
  flex-direction: row-reverse;
}

.text {
  flex: 1;
}

.text h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.text p {
  font-size: 1.2em;
  line-height: 1.6;
}

.image {
  flex: 1;
}

.image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.gallery {
  margin-top: 40px;
}

.gallery h2 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.gallery-item {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
  .text-image {
    flex-direction: column;
  }

  .reverse {
    flex-direction: column;
  }

  .section h2 {
    font-size: 1.5em;
  }

  .section p {
    font-size: 1em;
  }

  .gallery-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

/* Responsive styles for mobile devices */
@media (max-width: 480px) {
  .section h2 {
    font-size: 1.2em;
  }

  .section p {
    font-size: 0.9em;
  }
}




/* economy page css */


/* EconomyPage Styles */
.economy-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.section {
  margin-bottom: 40px;
}

.text-image {
  display: flex;
  align-items: center;
  gap: 20px;
}

.reverse {
  flex-direction: row-reverse;
}

.text {
  flex: 1;
}

.text h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.text p {
  font-size: 1.2em;
  line-height: 1.6;
}

.image {
  flex: 1;
}

.image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.gallery {
  margin-top: 40px;
}

.gallery h2 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.gallery-item {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
  .text-image {
    flex-direction: column;
  }

  .reverse {
    flex-direction: column;
  }

  .section h2 {
    font-size: 1.5em;
  }

  .section p {
    font-size: 1em;
  }

  .gallery-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

/* Responsive styles for mobile devices */
@media (max-width: 480px) {
  .section h2 {
    font-size: 1.2em;
  }

  .section p {
    font-size: 0.9em;
  }
}





/* contact page css */

.contact-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.contact-container > h2{
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  margin: 20px;
}
.contact-info {
  margin-bottom: 40px;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.info-item i {
  font-size: 1.5em;
  margin-right: 10px;
}

.contact-form h3 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
  .info-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .info-item i {
    margin-bottom: 5px;
  }
}

/* Responsive styles for mobile devices */
@media (max-width: 480px) {
  .contact-container {
    padding: 10px;
  }

  .contact-container button {
    width: 100%;
  }
}
